<template>
  <div class="main-content">
    <div class="flex align-items marginBottom20">
      <span class="marginRight10">读一读</span>
      <el-switch v-model="state" @change="getState"> </el-switch>
    </div>
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleCreate"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="picture" slot-scope="{ row }">
        <el-image
          style="width: 50px; height: 50px"
          :src="row.picture"
          :preview-src-list="[row.picture]"
        >
        </el-image>
      </template>
      <template slot="audioFrequency" slot-scope="{ row }">
        <div @click="playAudio(row)" v-if="row.audioFrequency">
          <span style="display: inline-block;width: 24px;height: 24px;">
            <img
              v-if="row.isPlaying"
              style="width: 100%; height: 100%"
              src="../../../style/image/suspend.svg"
              alt=""
            />
            <img
              v-else
              style="width: 100%; height: 100%"
              src="../../../style/image/broadcast.svg"
              alt=""
            />
          </span>
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <div style="margin-top: 15px;">
      <el-button size="medium" type="primary" @click="handleRevert"
        >返回</el-button
      >
    </div>
    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '编辑' : '新建'}`"
      :visible.sync="dialogVisible"
      width="650px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="sort">
          <el-input-number
            v-model="form.sort"
            controls-position="right"
            :min="1"
            :max="99999"
            style="width: 60%;"
            placeholder="输入排序值"
          >
          </el-input-number>
          <span style="color: #ddd;margin-left: 15px;">*数字越大越靠前</span>
        </template>
        <template slot="picture">
          <FormImgUpload
            :url="form.picture"
            @upload="upload"
            :limit="1"
            :maxSize="2"
            accept=".png,.jpg"
            :multiple="true"
            :disabled="false"
          />
          <!-- <div style="color:  #FF001F">
          建议尺寸：60px*60px，图片格式：.png，.jpg
        </div> -->
        </template>
        <!--   accept=".mp3,.wav,.ogg,.MP3,.WAV,.OGG" -->
        <template slot="audioFrequency">
          <FormFileUpload
            :url="form.audioFrequency"
            @upload="uploadAudio"
            :disabled="false"
            :limit="1"
            :maxSize="20"
            accept=".mp3,.wav,.MP3,.WAV"
            :multiple="true"
            :isFileName="form.audioName"
          />
          <div style="color: #FF001F">文件格式：.mp3,.wav</div>
        </template>
        <template slot="menuForm">
          <el-button @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            :loading="showLoadingForm"
            @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
    <audio ref="audios" onEnded="{getOnEnded}"></audio>
  </div>
</template>

<script>
import {
  courseReviewTasksList,
  courseAddTasks,
  courseUpdateTasks,
  courseReviewTasksView,
  courseDeleteReviewTasks,
  courseOpenOrCloseClassNumber,
} from "@/api/course/review";
import dayjs from "dayjs";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import FormFileUpload from "@/components/formComponents/formFileUpload";
import qs from "qs";
export default {
  name: "index",
  components: {
    FormImgUpload,
    FormFileUpload,
  },
  data() {
    return {
      searchData: {},
      id: this.$route.query.id,
      // state: this.$route.query.state,
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: false, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        selection: false,
        reserveSelection: true,
        selectionFixed: true,
        tip: false,
        column: [
          {
            label: "图片",
            prop: "picture",
          },
          {
            label: "英文",
            prop: "englishValue",
          },
          {
            label: "中文",
            prop: "chineseValue",
          },
          {
            label: "音频",
            prop: "audioFrequency",
          },
          {
            label: "排序",
            prop: "sort",
          },
        ],
      },
      form: {
        id: "",
        audioFrequency: [],
        picture: [],
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "120",
        row: true,
        column: [
          {
            label: "排序值",
            prop: "sort",
            type: "number",
            maxRows: 99999,
            minRows: 1,
            span: 24,
            row: true,
            append: "字",
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "图片",
            prop: "picture",
            type: "text",
            maxlength: 50,
            span: 11,
            rules: [
              {
                required: true,
                message: "请上传",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "音频",
            prop: "audioFrequency",
            type: "text",
            maxlength: 50,
            span: 11,
            // rules: [
            //   {
            //     required: true,
            //     message: "请上传",
            //     trigger: "blur",
            //   },
            //   {
            //     validator: (rule, value, callback) => {
            //       if (value.length === 0) {
            //         callback(new Error("请上传"));
            //       } else {
            //         callback();
            //       }
            //     },
            //   },
            // ],
          },
          {
            label: "英文",
            prop: "englishValue",
            maxlength: 5000,
            type: "textarea",
            span: 20,
            row: true,
            showWordLimit: true,
            placeholder: "输入英文",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "中文",
            prop: "chineseValue",
            maxlength: 5000,
            type: "textarea",
            span: 20,
            row: true,
            showWordLimit: true,
            placeholder: "输入中文",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      visiblePassWord: false, //密码弹窗
      currentPlayingIndex: -1,
      audioRefs: [],
      state: this.$route.query.state == 1, //读一读开启关闭按钮
      newPageTwo: this.$route.query.newPageTwo,
      newPage: this.$route.query.newPage,
      oneId: this.$route.query.oneId,
    };
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      courseReviewTasksList(this.id).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.map((item) => {
            return {
              ...item,
              isPlaying: false,
            };
          });
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 添加
    handleCreate() {
      this.form = {
        audioFrequency: [],
        picture: [],
      };
      this.dialogVisible = true;
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //编辑
    async handleEdit(row) {
      this.form.id = row.id;
      courseReviewTasksView(row.id).then((res) => {
        if (res.code == 200) {
          this.form = {
            ...res.data,
            picture: res.data.picture ? [res.data.picture] : [],
            audioFrequency: res.data.audioFrequency
              ? [res.data.audioFrequency]
              : [],
          };
        }
      });
      this.dialogVisible = true;
    },

    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        courseDeleteReviewTasks(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.$refs.crud.toggleSelection();
            this.onLoad();
          }
        });
      });
    },

    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          console.log(form, "formformform");
          // return
          if (form.id) {
            courseUpdateTasks({
              ...form,
              picture: this.form.picture.join(),
              audioFrequency: this.form.audioFrequency.join(),
              numberId: this.id,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          } else {
            courseAddTasks({
              ...form,
              picture: this.form.picture.join(),
              audioFrequency: this.form.audioFrequency.join(),
              numberId: this.id,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          }
          // this.form.type = 1;
          // done()
        }
      });
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },

    recert() {
      this.visiblePassWord = false;
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.picture = [...e];
      } else {
        this.form.picture = [...this.form.picture, e];
      }
    },
    //音频上传
    uploadAudio(e, name) {
      if (Array.isArray(e)) {
        this.form.audioFrequency = [...e];
      } else {
        this.form.audioFrequency = [...this.form.audioFrequency, e];
      }
      this.form.audioName = name;
    },
    //返回
    handleRevert() {
      // this.$emit("getPageTwo", this.newPageTwo);
      // this.$router.go(-1);
      this.$router.push({
        path: "/course/classNumber",
        query: {
          id: this.oneId,
          newPage: this.newPage,
          newPageTwo: this.newPageTwo,
        },
      });
    },
    playAudio(row) {
      this.tableData = this.tableData.map((item) => {
        if (row.id == item.id) {
          return {
            ...item,
            isPlaying: !row.isPlaying,
          };
        }
        return {
          ...item,
          isPlaying: false,
        };
      });
      this.btnPlay(row);
    },
    //播放录音
    btnPlay(record) {
      const audio1 = this.$refs.audios;
      const state = !record.playing;
      if (!record.isPlaying) {
        console.log("111111");
        audio1.src = record?.audioFrequency;
        audio1.play();
      } else {
        console.log("222222");
        audio1.pause();
      }

      // console.log(audio1.ended);
      // setIsPlaying(!state)
    },
    getOnEnded() {
      this.tableData = this.tableData.map((item) => {
        return {
          ...item,
          isPlaying: false,
        };
      });
    },
    getState() {
      try {
        courseOpenOrCloseClassNumber({
          id: this.id,
          state: this.state ? 1 : 0,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功!");
            this.$refs.crud.toggleSelection();
            this.onLoad();
          }
          if (res.code === 400) {
            this.state = this.$route.query.state == 1;
          }
        });
      } catch (err) {
        this.state = this.$route.query.state == 1;
      }
    },
  },
};
</script>

<style scoped></style>
