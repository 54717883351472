import { get, put, Delete, post } from "../axios";

// 课程管理-复习任务列表
export const courseReviewTasksList = (params) =>
  get(`/web/course/course/reviewTasksList/` + params, "");

// 复习任务新增
export const courseAddTasks = (params) =>
  post(`/web/course/course/addTasks`, params);

// 复习任务编辑
export const courseUpdateTasks = (params) =>
  post(`/web/course/course/updateTasks`, params);

// 课次查看
export const courseReviewTasksView = (params) =>
  get(`/web/course/course/reviewTasksView/` + params, "");

// 课次删除
export const courseDeleteReviewTasks = (params) =>
  Delete(`/web/course/course/deleteReviewTasks/` + params, "");

// 开启或者
export const courseOpenOrCloseClassNumber = (params) =>
  post(`/web/course/course/openOrCloseClassNumber`, params);
